import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate, useSearchParams } from '@remix-run/react';
import { CloseIcon, SearchIcon } from '@storefront/ui-components/shared/icons';
import { buildProductUrl } from '@storefront/util/auction/buildProductUrl';
import { formatPrice } from '@storefront/util/prices';
import truncate from 'lodash/truncate';
import type React from 'react';
import { type FC, useEffect, useRef, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import { useAuctionAutocomplete } from '../hooks/useAuctionAutocomplete';
import { useRegion } from '../hooks/useRegion';
import { IconButton } from '../shared/buttons';
import { Image } from '../shared/images/Image';

export const SearchBar: FC = () => {
  const { auctions } = useAuctionAutocomplete();
  const navigate = useNavigate();
  const { region } = useRegion();
  const searchRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const { query, refine, clear } = useSearchBox();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = searchParams.get('query') || '';

  useEffect(() => {
    refine(searchTerm);
  }, [searchTerm, refine]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    refine(newValue);
  };

  const handleClearSearch = () => {
    clear();
    setHasFocus(false);
    navigate('/search');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchRef.current?.blur();
      if (query.trim()) {
        setHasFocus(false);
        navigate(`/search${window.location.search}`);
      }
    }
  };

  const highlightTerm = (text: string, term: string) => {
    const regex = new RegExp(`(${term})`, 'gi');
    return text.replace(regex, '<strong>$1</strong>');
  };

  const shouldShowAutocomplete = query.length > 0 && hasFocus;

  return (
    <div className={`flex justify-center items-center ${shouldShowAutocomplete ? 'min-h-screen' : ''}`}>
      <div
        className={`${
          query.length > 0 || hasFocus ? 'w-[290px] md:w-[500px]' : 'w-32'
        } transition-all duration-100 ease-in-out`}
      >
        <div className="w-full rounded-lg relative">
          <div className="flex items-center relative">
            <div className="absolute">
              <SearchIcon className="w-6 h-6 ml-2 md:ml-3" aria-label="Search icon" />
            </div>
            <input
              ref={searchRef}
              type="text"
              placeholder="Search"
              value={query}
              onChange={handleInputChange}
              onFocus={() => setHasFocus(true)}
              onBlur={(e) => {
                // This is a hack to prevent the autocomplete from disappearing when the user clicks on a suggestion
                // Little bit of context:https://github.com/SaySo-co/lambda/pull/990/files#r1744706118
                setTimeout(() => {
                  if (!e.relatedTarget) {
                    setHasFocus(false);
                  }
                }, 10);
              }}
              onKeyDown={handleKeyDown}
              className="w-full outline-none border-none px-[30px] py-2 pl-[40px] h-9 md:h-auto text-lg bg-gray-100 rounded-full focus:border-none focus:ring-0"
            />
            {query.length > 0 && (
              <IconButton
                className="absolute right-2 cursor-pointer h-6 w-6"
                icon={() => <CloseIcon />}
                aria-label="Clear search"
                onClick={handleClearSearch}
              />
            )}
          </div>

          {shouldShowAutocomplete && (
            <div className="pb-5 flex flex-col absolute z-10 w-full bg-gray-200 rounded-md mt-2 shadow-md">
              <div className="flex flex-row items-center gap-1 ml-4 mb-3 mt-3">
                <div className="text-sm text-gray-600">Press enter to view {`${auctions.length}`} results for</div>
                <div className="font-bold">{`"${query}"`}</div>
              </div>
              <div className="max-h-[280px] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                {auctions.map((auction) => {
                  const percentageDiscount = Math.round(
                    Math.abs((auction.originalPrice - auction.currentPrice) / auction.originalPrice) * 100,
                  );
                  const hasDiscountBiggerThan30Percent = percentageDiscount >= 30;
                  return (
                    <Link
                      key={auction.objectID}
                      className="p-2 hover:bg-gray-300 cursor-pointer flex flex-row align-middle items-center justify-between group"
                      to={buildProductUrl(auction.handle)}
                      onClick={() => clear()}
                    >
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex flex-row align-middle items-center gap-2 px-4">
                          <Image
                            src={auction.imageLink}
                            alt={auction.title}
                            className="w-12 h-12 object-cover rounded-md"
                          />
                          <div>
                            <div
                              className="text-sm md:text-base"
                              // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                              dangerouslySetInnerHTML={{
                                __html: highlightTerm(truncate(auction.title, { length: 30, separator: ' ' }), query),
                              }}
                            />
                            <div className="flex flex-row gap-2">
                              <div className="text-gray-600 text-sm sm:text-base">
                                {formatPrice(auction.currentPrice, {
                                  currency: region.currency_code,
                                })}
                              </div>
                              {hasDiscountBiggerThan30Percent && Number.isFinite(percentageDiscount) && (
                                <span className="text-xs sm:text-sm font-bold px-[7px] py-[3px] sm:px-[10px] sm:py-[3px] bg-secondary-200 text-black rounded-[32px] w-auto">
                                  -{percentageDiscount}%
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <ArrowRightIcon className="w-6 h-6 mr-4 opacity-0 group-hover:opacity-100" />
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
