import { useLocation, useRouteLoaderData } from '@remix-run/react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useEnv } from '@storefront/ui-components/hooks/useEnv';
import { isSegmentLoaded } from '@storefront/util/analytics/segment/segment';
import { useSegmentIdentify } from '@storefront/util/analytics/segment/useSegmentIdentify';
import { isBrowser } from '@storefront/util/browser';
import { useEffect, useMemo } from 'react';
import type { ENV, RootLoader } from '../../utils/server/root.server';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
}

const useSetupSegment = () => {
  const { env } = useEnv();
  const writeKey = env.SEGMENT_WRITE_KEY;

  const segmentAnalytics = useMemo(() => {
    if (!writeKey) return;
    return AnalyticsBrowser.load({
      writeKey,
    });
  }, [writeKey]);

  if (segmentAnalytics && isBrowser() && !window.segmentAnalytics) {
    window.segmentAnalytics = segmentAnalytics;
  }

  return segmentAnalytics;
};

const setupGAScripts = (env: ENV | undefined, ID?: string) => {
  if (!env) return;

  const gtagIDs = env.GOOGLE_ANALYTICS_ID?.split(',') ?? [];
  const setupGtagConfig = (gtagID: string) => {
    const config: Record<string, string | boolean> = {
      debug_mode: env.NODE_ENV === 'development',
    };

    if (ID) {
      config.user_id = ID;
    }

    return `gtag('config','${gtagID}',${JSON.stringify(config)});`;
  };
  const setupGtagConfigScripts = gtagIDs.map((gtagID: string) => `${setupGtagConfig(gtagID)}`).join('\n');

  return (
    <>
      {env.GOOGLE_TAG_MANAGER?.startsWith('GTM-') && (
        <>
          <script
            // biome-ignore lint/security/noDangerouslySetInnerHtml: ignore
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${env.GOOGLE_TAG_MANAGER}');`,
            }}
          />
          <noscript>
            <iframe
              title="analytics setup iframe"
              src={`https://www.googletagmanager.com/ns.html?id=${env.GOOGLE_TAG_MANAGER}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      )}
      {env.GOOGLE_ANALYTICS_ID?.startsWith('G-') && <script async src={'https://www.googletagmanager.com/gtag/js'} />}
      {env.GOOGLE_ANALYTICS_ID?.startsWith('G-') && (
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: ignore
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            ${setupGtagConfigScripts}`,
          }}
        />
      )}
    </>
  );
};

export const SetupAnalytics = () => {
  const location = useLocation();
  const rootData = useRouteLoaderData<RootLoader>('root');
  const ID = rootData?.customer?.id;
  const env = rootData?.env;

  useSetupSegment();
  useSegmentIdentify();
  useEffect(() => {
    if (!isSegmentLoaded()) return;
    void window?.segmentAnalytics.page({
      path: location.pathname,
      search: location.search,
    });
  }, [location.pathname, location.search]);

  return setupGAScripts(env, ID);
};
