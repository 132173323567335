import { useLoaderData } from '@remix-run/react';
import { LoginModal } from '@storefront/ui-components/auth/login/LoginModal';
import { SignUpModal } from '@storefront/ui-components/auth/signup/SignupModal';
import { useEnv } from '@storefront/ui-components/hooks/useEnv';
import { SearchDrawer } from '@storefront/ui-components/search/SearchDrawer';
import { Footer } from '@storefront/ui-components/shared/footer';
import algoliasearch from 'algoliasearch';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { PostalCodeModal } from '~/components/postal/PostalCodeModal';
import { Header } from '../header/Header';

export interface PageProps {
  className?: string;
  children: ReactNode;
}

export const DefaultLayout: FC<PageProps> = ({ className, children }) => {
  return (
    <div className={clsx('page-layout flex min-h-screen flex-col bg-gray-50', className)}>
      <LoginModal />
      <SignUpModal />
      <PostalCodeModal />
      <SearchDrawer />
      <Header />
      <main className="flex-auto">
        <div className="w-full">{children}</div>
      </main>
      <Footer />
    </div>
  );
};
